import { get as get_data } from 'data/data';
import { get as get_cache, cache } from 'utils/cache';

export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'booking_setting',
        fields: 'key,value',
    }

    return get_data($.extend(true, {}, defaults, options));
}

export const get_food_preferences = (options) => {
    let d = $.Deferred();
    if (options == null) {
        options = {};
    }

    let cache_key = 'food_preferences';
    if (!$.isEmptyObject(options)) {
        cache_key += '_' + JSON.stringify(options)
    }
    let cached = get_cache(cache_key);
    if (cached != false) {
        if (options.on_done) {
            options.on_done(cached);
        }
        d.resolve(cached);
        return d.promise();
    }

    get({
        endpoint: 'booking_setting/food_preferences',
        field: '',
        data: options.data,
        on_done: function(json) {
            if (json.data) {
                cache(cache_key, json);
            }

            if (options.on_done) {
                options.on_done(json);
            }
            d.resolve(json);
        }
    });

    return d.promise();
}

export const get_notice = (options) => {
    let defaults = {
        endpoint: 'booking_setting/notice',
        version: BOOKING_VERSION,
        field: '',
    }

    return get($.extend(true, {}, defaults, options));
}

// get all ca settings like food_preferences and age_ranges
// caches all booking_settings requests in 'booking_settings'
// each distinct request (distinct options) is stored within 'booking_settings'
// 'booking_settings-food_preferences' and other cache is loaded based on the options.
export const get_all = (options) => {
    if (options == null) {
        options = {};
    }
    
    let cache_key = 'booking_settings';
    let cache_request = '*';
    if (!$.isEmptyObject(options)) {
        cache_request = JSON.stringify(options);
    }

    let defaults = {
        endpoint: 'booking_setting/all',
        version: BOOKING_VERSION,
        field: '',
        on_done: function(json) {
            if (json.data) {
                let cached = get_cache(cache_key);
                if (!cached) {
                    cached = {};
                }
                cached[cache_request] = json.data;

                cache(cache_key, cached);

                // cache each setting separately too
                for (let key in json.data) {
                    if (json.data[key]) {
                        cache('booking_settings-' + key, json.data[key]);
                    }
                }
            }
        }
    }
    if (options.on_done) {
        let on_done = options.on_done;
        options.on_done = function(json) {
            defaults.on_done(json);
            on_done();
        }
    }
    let cached = get_cache(cache_key);
    if (cached && cached.hasOwnProperty(cache_request)) {
        for (let key in cached[cache_request]) {
            const json_data = cached[cache_request];
            if (json_data[key]) {
                cache('booking_settings-' + key, json_data[key]);
            }
        }

        if (options && options.on_done) {
            options.on_done();
        }
        return null;
    }
    return get($.extend(true, {}, defaults, options));
}