import { 
    CLOSE_BTN_TEMPLATE, 
    NAV_TEMPLATE,
    SELECTED_TOUR_TEMPLATE,
    FOOTER_LOGO,
    ERROR_MESSAGE,
    LINK_EMAIL,
    MOBILE_SUBMIT_BAR,
} from 'booking-widget/v2/templates/shared';


export const PASSENGER_ROW = `
<div class="bw-passengers__row bw-row">
    <div class="bw-passengers__col bw-flex-lg-basis-33">
        <input type="hidden" name="is_child" value="[is_child]">
        <label for="full-name-[idx]">[is_child_label]</label>
        <input
            placeholder="[required_asterisk]First & Last Name"
            type="text"
            id="full-name-[idx]"
            data-contact-idx="[idx]"
            data-target="full-name-[idx]"
            name="full_name"
            value=""
            class="bw-mt--10"
            [full_name_atts]
        />
        <span class="bw-warning" style="display:none"></span>
    </div>
    <div class="bw-passengers__col bw-flex-lg-basis-33 bw-food-preferences">
        [food_preferences]
    </div>
</div>
`;

export const FOOD_PREFERENCE = `
    <span><label for="bw-food_preference-[idx]-[food_pref_key]"><input type="radio" id="bw-food_preference-[idx]-[food_pref_key]" name="food_preference_[idx]" value="[food_pref_value]" data-contact-idx="[idx]" data-target="food-preference-[idx]-[food_pref_value]" /> <span>[food_pref_label]</span></label><a href="#" data-ts-modal-open="[food_pref_modal_id]" class="bw-food-menu-link [hide_show_class]">?</a></span>
`;

export const INFO_TEMPLATE = `
<div class="bw bw-info">
    <div class="bw-header--trigger"></div>
    <div class="bw-header">
        ${NAV_TEMPLATE}
        ${CLOSE_BTN_TEMPLATE}
    </div>
    <div class="bw-title bw-desktop-only-block bw-mb--md-37">
        <h1 class="bw-gc-hide bw-text-center bw-mb--0">Passenger Information</h1>
        <h1 class="bw-gc-show bw-text-center bw-mb--0">Gift Certificate</h1>
    </div>
    <div class="bw-body">
        ${SELECTED_TOUR_TEMPLATE}
        <div class="bw-form-wrap">
            ${ERROR_MESSAGE}
            <p class="bw-mb--0 bw-lighter-font">
                Trip Protection: We understand that plans change! Enjoy full flexibility with a refund if you cancel up to 24 hours prior to departure (72 hours for groups of [max_group_size]).
            </p>

            <form class="bw-form bw-form--info" action="" autocomplete="on">
                <h2 class="bw-charters-hide bw-gc-hide">Passenger Names</h2>
                <div class="bw-passengers bw-charters-hide bw-gc-hide"></div>
                <hr>
                <label for="hotel-id" class="bw-sr bw-gc-hide">Hotel</label>
                <input type="text" name="hotel_id" 
                    data-autocomplete-field="hotel_id" 
                    data-autocomplete-key="hotel" 
                    data-target="hotel-id"
                    style="display:none"
                />
                <input aria-label="Hotel" type="text" name="hotel_name" class="autocomplete bw-gc-hide"
                    id="hotel-id" 
                    data-autocomplete-key="hotel"
                    data-autocomplete-field="name"
                    data-target="hotel-name"
                    placeholder="Accommodation on Kauai" 
                    autocomplete="off"
                />
                <hr>
                <div class="bw-group-section bw-charters-hide bw-gc-hide">
                    <p>Tour offered on multiple vessels. If you're with someone who booked separately, we can get you on the same boat.</p>
                    <label class="show" for="bw-enable-group-name"><input type="checkbox" id="bw-enable-group-name"> I'm with a group that booked separately</label>

                    <div class="bw-group-name" style="display:none">
                        <div class="bw-my--15">
                            <label for="bw-group-name" class="bw-sr">Group Name</label>
                            <input type="text" name="reservation_group_name" placeholder="Group Name" id="bw-group-name" data-target="reservation-group-name" disabled="disabled">
                        </div>
                        <p>If you don't know your group's name contact us (${LINK_EMAIL}) at least 24 hours before departure</p>
                    </div>
                </div>
                <div class="bw-charters-requests"></div>

                <input type="submit" class="bw-button bw-form-submit bw-desktop-only" data-original="Continue to Payment" value="Continue to Payment">
            </form>
        </div>
    </div>
    <div class="bw-footer bw-desktop-only-flex">
        ${FOOTER_LOGO}
    </div>
    ${MOBILE_SUBMIT_BAR}
</div>

<div class="bw-food-preference-popup-container" style="display: none"></div>
`;

export const CHARTERS_REQUESTS = `
    <h2 class="bw-mt--30 bw-mb--15">Services & Requests</h2>
    <p class="bw-mb--15">Prior to your special adventure, we can contact you to discuss the menu, activities, logistics and other concerns to provide the best experience possible.<br><br>
    Please add any notes below that will assist in the process.</p>
    <textarea class="bw-mb--15" rows="8" name="reservation_note" placeholder="Notes and requests" data-target="reservation-notes"></textarea>
    <div class="bw-select-container bw-mb--15">
        <select class="bw-primary" name="contact_method" data-target="contact-method">
            <option value="">Select a preferred contact method</option>
            <option value="Email">Email</option>
            <option value="Phone">Phone</option>
        </select>
        <span class="bw-icon--chevron-down"></span>
    </div>
`;

export const GC_LINK_FORM = `
    <div class="bw-selected-tour__gc-link bw-gc-hide">
        <a href="#">Use a gift certificate or promo code&nbsp;<span class="bw-icon--chevron-up"></span>
</svg>
</a>
    </div>
    <div class="bw-selected-tour__gc-form" style="display:none;">
        <div class="bw-gift-promo bw-charters-hide bw-gc-hide">
            <form class="bw-form bw-form--update-pricing bw-gift-promo-form" action="" autocomplete="on">
                <input type="text" name="gift_promo_code" placeholder="Gift / Promo Code" class=" bw-flex-lg-basis-60" required data-target="gift-promo-code"><input type="submit" class="bw-button bw-form-submit bw-flex-lg-basis-40 bw-fwxd--500 bw-fsxd--16" value="Update Pricing">
                <input type="hidden" name="gift_certificate_id" data-target="gift-certificate-id">
                <input type="hidden" name="promo_code_id" data-target="promo-code-id">
            </form>
            <div class="bw-errors bw-gift-promo__errors" style="display: none;"><div class="bw-errors__list"></div></div>
            <div class="bw-gift-promo__verified bw-gift-promo__verified--promo bw-mt--15" style="display:none">
                Promo Code Verified: <span class="bw-gift-promo__code bw-gift-promo__code--promo" data-target="promo-code"></span><a href="#" class="bw-gift-promo__remove--promo">clear</a>
            </div>
            <div class="bw-gift-promo__verified bw-gift-promo__verified--gift bw-mt--15" style="display:none">
                Gift Code Verified: <span class="bw-gift-promo__code bw-gift-promo__code--gift" data-target="gift-code"></span><a href="#" class="bw-gift-promo__remove--gift">clear</a>
            </div>
        </div>
        ${ERROR_MESSAGE}
    </div>
`;