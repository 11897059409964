import { init as setup_popup, destroy_popup } from 'ui/popup';
import { exclusion_string } from 'booking-widget/v2/common/util';
import { get as get_cache } from 'utils/cache';
import { esc_html } from 'utils/strings';

// only creates at most one popup element per page
export const init = (options) => {
    if (!options) {
        options = {};
    }

    let body_el = $('body');
    let popup_id = options.popup_id ? options.popup_id : 'bwgp-widget';
    let display_el = options.display_el ? options.display_el : $();
    let is_compact = options.hasOwnProperty('is_compact') ? options.is_compact : false;
    let is_tour_specific = !!options.tour_id;
    let MAX_CAPACITY = 49; // will be replaced when results load.
    let capacity = MAX_CAPACITY;
    let inital_counts = {
        adult: options.adult ? (parseInt(options.adult) || 2) : 2,
        child: options.child ? (parseInt(options.child) || 0) : 0,
        toddler: (!options.is_raft_only && options.toddler) ? (parseInt(options.toddler) || 0) : 0,
    }
    // booking_settings should already be cached
    let age_ranges = get_cache('booking_settings-age_ranges');
    let url_safety = get_cache('booking_settings-url_safety');
    let is_raft_only_class = options.is_raft_only ? 'bw-raft-only' : '';
    const is_tour_specific_class = is_tour_specific ? 'bw-tour-specific' : '';

    const age_range_content = {
        adult: 'Age ' + esc_html(age_ranges.adult.start || 13) + '+',
        child: 'Age ' + esc_html(age_ranges.child.start || 6) + '-' + esc_html(age_ranges.child.end || 12),
        toddler: 'Age ' + esc_html(age_ranges.toddler.start || 3) + '-' + esc_html(age_ranges.toddler.end || 5)
    }

    const gp_content = {
        adult: 'Adults',
        child: 'Children',
        toddler: 'Toddlers',
        charter: false
    };

    if (!is_tour_specific) {
        gp_content.adult += `<br><span class="bw-dark-gray">${age_range_content.adult}</span>`;
        gp_content.child += `<br><span class="bw-dark-gray">${age_range_content.child}</span>`;
        gp_content.toddler += `<br><span class="bw-dark-gray">${age_range_content.toddler}</span>`;
    }
    else {
        const tv_sample = get_cache('ca-tv-v2-tour-' + options.tour_id);
        let adult_price_str = false;
        let child_price_str = false;
        let charter_price = false;

        if (tv_sample) {
            const adult_low = Number(tv_sample.adult_price_base_low);
            const adult_high = Number(tv_sample.adult_price_base_high);

            const child_low = Number(tv_sample.child_price_base_low);
            const child_high = Number(tv_sample.child_price_base_high);

            adult_price_str = displayUsd(adult_low);
            child_price_str = displayUsd(child_low);

            if (adult_low != adult_high) {
                adult_price_str += '-' + displayUsd(adult_high).replace('$', '');
            }

            if (child_low != child_high) {
                child_price_str += '-' + displayUsd(child_high).replace('$', '');
            }

            charter_price = adult_low * Number(tv_sample.vessel[0].capacity_max);
        }

        gp_content.adult += ` (${age_range_content.adult.toLowerCase()})`;
        if (adult_price_str) {
            gp_content.adult += `<br><span class="bwgp-widget__guest-price">${adult_price_str}</span>`;
        }

        gp_content.child += ` (${age_range_content.child.toLowerCase()})`;
        if (child_price_str) {
            gp_content.child += `<br><span class="bwgp-widget__guest-price">${child_price_str}</span>`;
        }

        gp_content.toddler += ` (${age_range_content.toddler.toLowerCase()})`;
        if (child_price_str) {
            gp_content.toddler += `<br><span class="bwgp-widget__guest-price">${child_price_str}</span>`;
        }

        if (charter_price) {
            gp_content.charter = `<a href='${URL_ROOT_PUBLIC}private-charters/' class="bw-link-underline">Private Charter</a>`;
            gp_content.charter += `<br><span class="bwgp-widget__guest-price">from ${displayUsd(charter_price)}</span>`;
        }
    }

    let html = `
        <div class="bw-popup bwgp-widget" id="${popup_id}" aria-hidden="true" data-popup-name="${popup_id}">
            <div class="bw-popup__container bwgp-widget__container" role="dialog" aria-popup="true" aria-labelledby="${popup_id}-title">
                <main class="bw-popup__content ${is_tour_specific_class}">
                    <div class="bwgp-widget__guest-row">
                        <div class="bwgp-widget__guest-col">
                            <span class="bwgp-widget__guest-desc">${gp_content.adult}</span>
                        </div>
                        <div class="bwgp-widget__guest-col bwgp-widget__input-group">
                            <a href="#" class="bw-icon--minus bwgp-widget__input--minus"></a>
                            <div contenteditable="true" spellcheck="false" class="bwgp-widget__guest-count bwgp-widget__guest-count--adult">${inital_counts.adult}</div>
                            <a href="#" class="bw-icon--plus bwgp-widget__input--plus"></a>
                        </div>
                    </div>
                    <div class="bwgp-widget__guest-row">
                        <div class="bwgp-widget__guest-col">
                            <span class="bwgp-widget__guest-desc">${gp_content.child}</span>
                        </div>
                        <div class="bwgp-widget__guest-col bwgp-widget__input-group">
                            <a href="#" class="bw-icon--minus bwgp-widget__input--minus"></a>
                            <div contenteditable="true" spellcheck="false" class="bwgp-widget__guest-count bwgp-widget__guest-count--child">${inital_counts.child}</div>
                            <a href="#" class="bw-icon--plus bwgp-widget__input--plus"></a>
                        </div>
                    </div>
                    <div class="bwgp-widget__guest-row bwgp-widget__guest-row--toddler ${is_raft_only_class}">
                        <div class="bwgp-widget__guest-col">
                            <span class="bwgp-widget__guest-desc">${gp_content.toddler}</span>
                        </div>
                        <div class="bwgp-widget__guest-col bwgp-widget__input-group">
                            <a href="#" class="bw-icon--minus bwgp-widget__input--minus"></a>
                            <div contenteditable="true" spellcheck="false" class="bwgp-widget__guest-count bwgp-widget__guest-count--toddler">${inital_counts.toddler}</div>
                            <a href="#" class="bw-icon--plus bwgp-widget__input--plus"></a>
                        </div>
                    </div>`
    ;
    if (gp_content.charter) {
        html += `
                    <div class="bwgp-widget__guest-row bwgp-widget__guest-row--charter">
                        <div class="bwgp-widget__guest-col">
                            <span class="bwgp-widget__guest-desc">${gp_content.charter}</span>
                        </div>
                        <div class="bwgp-widget__guest-col bwgp-widget__input-group">
                        </div>
                    </div>`
        ;
    }
    html += `
                    <div class="bwgp-widget__details"></div>
                </main>
            </div>
        </div>
    `;

    // let opener_el = $();
    setup_popup({
        html: html,
        on_setup: function(popup_el) {
            setup_bindings(popup_el, capacity);
        },
        on_shown: function(e, params) {
            let popup_el = params.el;
            let opener_el = params.opener_el;
            capacity = (is_tour_specific && opener_el[0].hasAttribute('data-capacity')) ? opener_el.attr('data-capacity') : MAX_CAPACITY;
            
            $('.bwgp-widget__input-group .bwgp-widget__guest-count', popup_el).each(function() {
                let min = $(this).hasClass('bwgp-widget__guest-count--adult') ? 1 : 0;
                toggle_disabled_class($(this), min);
            })

            body_el.addClass('bw-bwgp-widget-showing');

            populate_exclusions(popup_el, opener_el);
            if (options.on_shown) {
                options.on_shown(popup_el, opener_el);
            }
        },
        on_hidden: function(popup_el) {
            display_el.attr('data-touched', 1);
            let guest_counts = get_guest_counts(popup_el);
            set_guestpicker_display(guest_counts, display_el, is_compact);
            
            body_el.removeClass('bw-bwgp-widget-showing');

            if (options.on_hidden) {
                options.on_hidden(popup_el, guest_counts);
            }
        }
    });

    let guestpicker_vals = false;
    if (options.adult !== false || options.child !== false || options.toddler !== false) {
        guestpicker_vals = inital_counts;
    }

    set_guestpicker_display(guestpicker_vals, display_el, is_compact);


    /**
     * Construct the exclusion text based of the attributes in opener_el
     */
    function populate_exclusions(popup_el, opener_el) {
        let details_el = $('.bwgp-widget__details', popup_el);
        details_el.empty();

        let exclusions = opener_el.attr('data-exclusions');
        if (!exclusions || exclusions == '') {
            exclusions = {
                // <toddlers_allowed>-<pregnant_allowed>
                catamaran: '1-2',
                raft: '0-0',
            }
        }
        else {
            exclusions = JSON.parse(exclusions);
        }
        
        let str = '';
        for (let vessel_type in exclusions) {
            let combo = exclusions[vessel_type].split('-');
            if (combo.length !== 2) {
                continue;
            }
            let toddlers_allowed = combo[0];
            let pregnant_allowed = combo[1];

            if (Object.keys(exclusions).length > 1) {
                str += vessel_type == 'catamaran' ? '<span class="bw-fw-bold">Catamarans</span>: ' : '<span class="bw-fw-bold">Rafting</span>: ';
            }
            str += exclusion_string(toddlers_allowed, pregnant_allowed);
        }
        let safety_link = '';
        if (url_safety) {
            safety_link = '<a href="' + encodeURI(url_safety.url) + '" target="_blank">' + esc_html(url_safety.text) + '</a>';
        }
        details_el.append('<p class="bgp-exclusion">' + str + safety_link + '</p>');
    }

};

export const get_guest_counts = (widget_el) => {
    let guest_counts = { adult: 2, child: 0, toddler: 0 };
    $('.bwgp-widget__guest-count', widget_el).each(function() {
        if ($(this).hasClass('bwgp-widget__guest-count--adult')) {
            guest_counts.adult = parseInt($(this).text()) || 1;
        }
        else if ($(this).hasClass('bwgp-widget__guest-count--child')) {
            guest_counts.child = parseInt($(this).text()) || 0;
        }
        else if ($(this).hasClass('bwgp-widget__guest-count--toddler')) {
            guest_counts.toddler = parseInt($(this).text()) || 0;
        }
    });
    return guest_counts;
};


export const set_guest_counts = (widget_el, guest_counts) => {
    $('.bwgp-widget__guest-count', widget_el).each(function() {
        if ($(this).hasClass('bwgp-widget__guest-count--adult')) {
            $(this).text(parseInt(guest_counts.adult) || 1);
        }
        else if ($(this).hasClass('bwgp-widget__guest-count--child')) {
            $(this).text(parseInt(guest_counts.child) || 0);
        }
        else if ($(this).hasClass('bwgp-widget__guest-count--toddler')) {
            $(this).text(parseInt(guest_counts.toddler) || 0);
        }
    });
};

    
export const set_guestpicker_display = (guest_counts, display_el, is_compact) => {
    let str = '';
    const counts = {
        adult: (guest_counts && guest_counts.adult) ? guest_counts.adult : 2,
        child: (guest_counts && guest_counts.child) ? guest_counts.child : 0,
        toddler: (guest_counts && guest_counts.toddler) ? guest_counts.toddler : 0,
    }
    if (!is_compact) {
        let lbl = counts.adult > 1 ? 'Adults' : 'Adult';
        str = '<span>' + counts.adult + ' ' + lbl + '</span>';
        if (counts.child > 0) {
            lbl = counts.child > 1 ? 'Children' : 'Child';
            str += ' <span>' + counts.child + ' ' + lbl + '</span>';
        }
        if (counts.toddler > 0) {
            lbl = counts.toddler > 1 ? 'Toddler' : 'Toddlers';
            str += ' <span>' + counts.toddler + ' ' + lbl + '</span>';
        }
    }
    else {
        let guests = parseInt(counts.adult) + parseInt(counts.child) + parseInt(counts.toddler);
        str = '<span>' + guests + '</span>';
    }

    $('.bw-pickers__guestpicker-display', display_el).addClass('bw-picked');
    $('.bw-pickers__guestpicker-display', display_el).html(str);
}


function setup_bindings(widget_el, _capacity) {
    const capacity = _capacity;
    $('.bwgp-widget__input--plus, .bwgp-widget__input--minus', widget_el)
        .on('click', function(e) {
            e.preventDefault();
            let input_el = $(this).closest('.bwgp-widget__input-group').find('.bwgp-widget__guest-count');
            let change = 0;
            if ($(this).hasClass('bwgp-widget__input--plus')) {
                change = 1;
            }
            else {
                change = -1;
            }
            let min = input_el.hasClass('bwgp-widget__guest-count--adult') ? 1 : 0;
            let count = handle_count_change(input_el.text(), min, capacity, change);
            input_el.text(count);
            toggle_disabled_class(input_el, min);
            input_changed(input_el);
        });

    $('.bwgp-widget__guest-count', widget_el)
        .on('blur', function() {
            let min = $(this).hasClass('bwgp-widget__guest-count--adult') ? 1 : 0;
            let num = handle_count_change($(this).text(), min, capacity, 0);
            $(this).text(num);
            let minus_el = $('.bwgp-widget__input--minus', $(this).closest('.bwgp-widget__input-group'));
            toggle_disabled_class(minus_el, min);
            input_changed($(this));
        })
        .on('keydown', function(e) {
            if (e.key === 'Enter' || e.keyCode === 13) {
                e.preventDefault();
                let min = $(this).hasClass('bwgp-widget__guest-count--adult') ? 1 : 0;
                let num = handle_count_change($(this).text(), min, capacity, 0);
                $(this).text(num);
                toggle_disabled_class($(this), min);
                hide_popup(widget_el);
                input_changed($(this));
            }
        })
    ;

    $('.bwgp-widget__guest-count', widget_el).each(function() {
        let min = $(this).hasClass('bwgp-widget__guest-count--adult') ? 1 : 0;
        toggle_disabled_class($(this), min);
    })
}

function toggle_disabled_class(input_el, min) {
    let minus_el = $('.bwgp-widget__input--minus', input_el.closest('.bwgp-widget__input-group'));
    let val = input_el.text();
    if (val <= min) {
        minus_el.addClass('disabled');
    }
    else {
        minus_el.removeClass('disabled');
    }
}

function handle_count_change(val, min, max, change) {
    let num = parseInt(val) || 0;
    num += change;
    return Math.max(Math.min(num, max), min);
}

function displayUsd(num) {
    if (isNaN(num)) {
        return '';
    }

    return (new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: 0, 
        minimumFractionDigits: 0,
    })).format(num);
}

function input_changed(changed_el) {
    $(document).trigger('bw-gp-changed', changed_el)
}

export const destroy_guestpicker = () => {
    destroy_popup();
}