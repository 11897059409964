// alphabetized except when a template has a dependency

export const CLOSE_BTN_TEMPLATE = `
<div class="bw-close">
    <a href="#"><span class="bw-icon--x"></span><span class="bw-close__label">Close</span></a>
</div>
`;

export const CLOSE_MODAL_TEMPLATE = `<a href="#" class="bw-button" data-ts-modal-close>Close</a>`;

export const CLOSEX_MODAL_TEMPLATE = `<a href="#" class="bw-button-x bw-icon--x" data-ts-modal-close>close</a>`;

export const CONTACT_INFO_FIELDS = `
    <div class="bw-email-inputs">
        <input type="text" name="email" placeholder="Email*" data-target="email" required>
        <span class="bw-warning" style="display:none" data-target="warning-email"></span>
    </div>
    <div>
        <input type="text" name="phone" placeholder="Mobile Phone*" class="intltelinput" data-target="phone" required>
        <span class="bw-warning" style="display:none" data-target="warning-phone"></span>
    </div>
    <span><label class="show" for="opt-in-sms"><input type="checkbox" id="opt-in-sms" class="bw-mr--10" checked /><span class="bw-opt-in-general">Reservation related texts (e.g., boarding passes).</span><span class="bw-opt-in-waitlist bw-d-none">YES, I'm in! I agree to opt-in and receive important messages about this waitlist reservation. If I opt-out and uncheck the box, I WILL NOT be notified if space becomes available on this tour, and I will not receive messages for any future reservations with Capt Andy's. Please be sure the box is checked to receive improtant information about this waitlist reservation. Standard text message rates apply.</span></label></span>
    <input type="hidden" name="opt_in" class="" value="1">
`;

export const DATE_ITEM_TEMPLATE = `
<a href="#" class="bw-date-item" data-date="">
    <span class="bw-date-item__content">
        <span class="day"></span>
        <span class="date"></span>
        <span class="month"></span>
    </span>
</a>`;

export const ERROR_MESSAGE = `
<div class="bw-errors bw-form-errors bw-mb--md-40" style="display:none;">
<h2>Oops.</h2>
<p class="bw-standard-error-msg">The information in the submitted fields did not process correctly.<br>Please fix the fields <span class="bw-error">highlighted in red</span> below.</p>
<p class="bw-fs-md bw-mb--0 bw-standard-contact-msg">You can always <a href="[contact_us_url]">contact us</a> for assistance.</p>
<div class="bw-errors__list bw-mt--1em"></div>
</div>
`;

export const FOOTER_LOGO = `
    <img src="[logo_url]" width="300" alt="Capt. Andy's logo" />
`;

export const BW_VERSION = `
    <div class="bw-version" aria-hidden="true">v${BOOKING_VERSION}</div>
`;

export const FS_LOADING_TEMPLATE = '';

export const MODAL_TEMPLATE = `
    <section class="bw-modal" id="[modal_id]" aria-hidden="true">
        <div class="bw-modal__overlay" tabindex="-1" data-ts-modal-close>
            <div class="bw-modal__container" role="dialog" aria-modal="true">
                <main class="bw-modal__content"></main>
            </div>
        </div>
    </section>
`;

export const NAV_TEMPLATE = `
<div class="bw-nav-wrap">
    <div class="bw-nav">
        <a class="bw-nav__item bw-nav__item--search">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label">Date <span class="bw-desktop-only">/ Guests</span></span>
        </a>
        <a class="bw-nav__item bw-nav__item--tours">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label"><span class="bw-desktop-only">Select</span> Tour</span>
        </a>
        <a class="bw-nav__item bw-nav__item--info">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label"><span class="bw-desktop-only">Your</span> Info</span>
        </a>
        <a class="bw-nav__item bw-nav__item--payment">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label">Payment</span>
        </a>
        <a class="bw-nav__item bw-nav__item--confirmed">
            <span class="bw-nav__item__circle"></span>
            <span class="bw-nav__item__label">Confirm</span>
        </a>
    </div>
</div>
`;

export const PAGE_ERROR = `
<div class="bw-page-error">
    <h1>[error_title]</h1>
    <p>[error_message]</p>
</div>
`;


export const SELECTED_TOUR_TEMPLATE = `
<div class="bw-selected-tour">
    <p class="bw-selected-tour__message">[message]</p>
    <div class="bw-selected-tour__tour-info bw-gc-hide">
        <div class="bw-selected-tour__tour-content">
            <div class="bw-selected-tour__tour-details-line">
                <div class="bw-selected-tour__date bw-col">${DATE_ITEM_TEMPLATE}</div>
                <div class="bw-selected-tour__checkin bw-col">
                    <span class="bw-icon--clock bw-mb--6"></span>
                    <span class="bw-fs--sm bw-fs-md--md-3 bw-fwxd--700 bw-fwxd--md-400">[checkin]</span>
                    <span class="bw-fsxd--10 bw-fsxd--md-14">Check in<span class="bw-desktop-only"> Time</span></span>
                </div>
                <div class="bw-selected-tour__location bw-col">
                    <span class="bw-icon--location bw-mb--6"></span>
                    <span class="bw-fs--sm bw-fs-md--md-3 bw-fwxd--700 bw-fwxd--md-400 bw-text-center">[location]</span>
                    <a href="[directions_uri]" target="_blank" class="bw-fsxd--10 bw-fsxd--md-14 bw-default-color">Directions</a>
                </div>
                <div class="bw-selected-tour__tour-image bw-col">
                    <img src="[tour_image_url]" alt="[tour_name]">
                </div>
            </div>
            <div class="bw-selected-tour__tour-name-line">
                <div class="bw-selected-tour__name">[tour_name]</div>
                <div class="bw-selected-tour__tour-tags bw-fsxd--12 bw-fsxd--md-15">[highlights]</div>
            </div>
        </div>
    </div>
    <div class="bw-selected-tour__price-list">
        <div class="bw-selected-tour__price-list__line">
            <span>[adult_count] [adult_label] [adult_price_base]</span>
            <span>[adult_price_total]</span>
        </div>
        <div class="bw-selected-tour__price-list__line bw-selected-tour__price-list__line--child" style="display:none">
            <span>[child_count] [child_label] [child_price_base]</span>
            <span>[child_price_total]</span>
        </div>
        <div class="bw-selected-tour__price-list__line bw-selected-tour__price-list__line--discount" style="display:none">
            <span>You Saved</span>
            <span class="bw-negative" data-target="discount">[discount]</span>
        </div>
        <div class="bw-selected-tour__price-list__line">
            <span>Tax/Fees</span>
            <span data-target="taxes-fees">[taxes_fees]</span>
        </div>
        <div class="bw-selected-tour__price-list__line bw-selected-tour__price-list__line--gift" style="display:none">
            <span>Gift Certificate</span>
            <span data-target="gift-code-amount">[gift_code_amount]</span>
        </div>
    </div>
    <div class="bw-selected-tour__total">
        <span>Total <span class="bw-text-transform-none">[payment_info_html]</span></span>
        <span data-target="total-price">[total_price]</span>
    </div>
    [gc_form]
    <div class="bw-selected-tour__payment-notes bw-fsxd--12 bw-fsxd--md-14">
        <div class="bw-flex-basis-50 bw-gray666">Taxes & fees included</div>
        <a href="#" class="bw-flex-basis-50 bw-cancel-policy-link bw-text-right bw-underline bw-gray666">Cancellation Policy</a>
    </div>
    <div class="bw-cancel-policy" style="display:none;">
        <p>Cancellation Policy: Enjoy flexibility with a full refund if you cancel up to 24 hours prior to departure (72 hours for groups of 10+). If weather prevents a safe Na Pali tour, we'll explore the beautiful South Shore, or you can choose to reschedule or receive a full refund.</p>
        <a href="#" class="bw-cancel-policy__close"><span class="bw-icon--x"></span></a>
    </div>
    <div class="bw-selected-tour__notes" style="display:none">
        <div class="bw-selected-tour__passengers bw-flex-lg-basis-50" style="display:none">
            <div class="bw-mb--6 bw-fs--md"><strong>Passengers</strong></div>
            [passenger_names]
        </div>
        <div class="bw-selected-tour__contact bw-flex-lg-basis-50" style="display:none">
            <div class="bw-mb--6 bw-fs--md"><strong>[contact_label]</strong></div>
            Contact Name: <strong>[primary_name]</strong><br>
            Contact Email: <strong>[primary_email]</strong><br>
            Contact Phone: <strong>[primary_phone]</strong><br>
            Number of Guests: <strong>[guest_count]</strong><br>
            [notes]
        </div>
    </div>
</div>
`;

export const MOBILE_SUBMIT_BAR = `
<div class="bw-mobile-submit-bar">
    <div class="bw-mobile-submit-bar__total">
        <div class="bw-ml--10 bw-fwxd--700 bw-fsxd--16" data-target="submit-bar-total">[submit_bar_total]</div>
        <div class="bw-fsxd--12 bw-gray666">Taxes & fees included</div>
    </div>
    <div class="bw-mobile-submit-bar__submit"><a class="bw-mobile-submit-bar__button" href="#">[submit_bar_link_text]</a></div>
</div>
`;

export const TOUR_CARD_TAG = `<span class="bw-tour-tag">[tag]</span>`;

export const LINK_EMAIL = '<a href="mailto:fun@napali.com">fun@napali.com</a>';
export const LINK_PHONE = '<a href="tel:+18083356833">808-335-6833</a>';


export const ICON_INFO = '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"><path class="abc" d="m8 0c-4.42 0-8 3.58-8 8s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8z" fill="none"/><g class="line" fill="#425364"><path d="m8 1.2c-3.75 0-6.8 3.05-6.8 6.8s3.05 6.8 6.8 6.8 6.8-3.05 6.8-6.8-3.05-6.8-6.8-6.8m0-1.2c4.42 0 8 3.58 8 8s-3.58 8-8 8-8-3.58-8-8 3.58-8 8-8z"/><path d="m8.01 5.39c-.33 0-.61-.1-.82-.3s-.32-.44-.32-.73.11-.54.32-.73.49-.28.82-.28.61.09.82.28.32.43.32.73-.11.55-.32.74-.48.29-.82.29zm.98 7.41h-1.97v-6.4h1.97z"/></g></svg>';

export const ICON_ALERT = '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="bg" d="m12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12z" fill="#4da1d1"/><path class="line" d="m12.01 19.42c-.5 0-.91-.15-1.24-.45s-.49-.67-.49-1.1.16-.82.49-1.11.74-.43 1.23-.43.91.15 1.23.44.48.66.48 1.1-.16.82-.47 1.12c-.32.29-.73.44-1.23.44zm1.54-13.66-.37 9.26h-2.4l-.35-9.26h3.11z" fill="#fff"/></svg>';

export const ICON_HELP = '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="bg" d="m12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12z" fill="#4da1d1"/><path class="line" d="m10.26 15.31c-.06-.16-.11-.35-.15-.55-.03-.2-.05-.39-.05-.58 0-.29.03-.55.08-.79.06-.24.14-.46.25-.67s.25-.41.42-.6.37-.38.59-.57c.24-.2.45-.39.62-.56.18-.17.33-.34.45-.52.12-.17.22-.35.28-.53s.09-.39.09-.61c0-.19-.03-.37-.1-.54s-.17-.31-.3-.43-.28-.21-.47-.28c-.18-.07-.39-.1-.61-.1-.49 0-.99.1-1.52.3-.52.2-1.02.51-1.49.92v-2.82c.48-.29 1.01-.5 1.58-.65.57-.14 1.16-.22 1.76-.22s1.12.06 1.62.19.94.33 1.31.6c.37.28.66.63.87 1.05.21.43.31.94.31 1.54 0 .38-.04.73-.13 1.04s-.22.6-.38.88c-.17.28-.38.54-.63.79s-.54.51-.86.78c-.22.18-.41.35-.58.51s-.3.31-.41.46-.19.32-.25.49-.08.37-.08.59c0 .15.02.3.06.46s.1.29.16.39h-2.47zm1.37 4.11c-.5 0-.92-.15-1.25-.45-.33-.31-.5-.68-.5-1.11s.17-.8.5-1.1c.33-.29.75-.44 1.25-.44s.9.15 1.23.44.49.65.49 1.1-.16.82-.48 1.12c-.33.29-.74.44-1.24.44z" fill="#fff"/></svg>';

export const ICON_CHECKMARK = '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path class="bg" d="m12 0c-6.63 0-12 5.37-12 12s5.37 12 12 12 12-5.37 12-12-5.37-12-12-12z" fill="#4da1d1"/><path class="line" d="m9.77 16.63-4.57-4.18c-.25-.22-.28-.6-.05-.86.02-.02.04-.04.05-.05l.99-.91c.28-.25.71-.25.99 0l3.08 2.82 6.6-6.04c.28-.25.71-.25.99 0l.99.91c.25.22.28.6.05.86-.02.02-.04.04-.05.05l-8.09 7.4c-.28.25-.71.25-.99 0z" fill="#fff"/></svg>';

export const ICON_LOCK = '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="25.826" viewBox="0 0 18 25.826"><g id="XMLID_504_" transform="translate(-50)"><path id="XMLID_505_" d="M51.174,25.826H66.826A1.174,1.174,0,0,0,68,24.652v-13.3a1.174,1.174,0,0,0-1.174-1.174H65.652V6.652a6.652,6.652,0,1,0-13.3,0v3.522H51.174A1.174,1.174,0,0,0,50,11.348v13.3A1.174,1.174,0,0,0,51.174,25.826Zm11.151-8.659L59,20.493a1.174,1.174,0,0,1-1.66,0L55.675,18.83a1.174,1.174,0,0,1,1.66-1.66l.833.833,2.5-2.5a1.174,1.174,0,1,1,1.66,1.66ZM54.7,6.652a4.3,4.3,0,1,1,8.609,0v3.522H54.7Z" transform="translate(0)" fill="green"/></g></svg>';