import { 
    ICON_INFO,
    ICON_ALERT,
    ICON_HELP,
    ICON_CHECKMARK,
    ICON_LOCK
} from 'booking-widget/v2/templates/shared';

export const PICKERS_TEMPLATE = `
    <div class="bw bw-search">
        <div class="bw-pickers">
            [search_header]
            <div class="bw-pickers-row">
                <div class="bw-pickers__picker-col bw-pickers__picker-col--date">
                    <label for="bw-pickers-picker-datepicker"><span class="bw-desktop-only">Tour </span> Date</label>
                    <button id="bw-pickers-picker-datepicker" class="bw-pickers__picker bw-pickers__picker--datepicker bw-pickers__button" data-touched="0">
                        <span class="bw-pickers__picker-text bw-pickers__datepicker-display"></span>
                        <span class="bw-pickers__picker-toggle bw-icon--chevron-down"></span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--gp">
                    <label for="bw-pickers-picker-guestpicker">Passengers</label>
                    <button id="bw-pickers-picker-guestpicker" class="bw-pickers__picker bw-pickers__picker--guestpicker bw-pickers__button" 
                        data-guests-adult="2" 
                        data-guests-child="0" 
                        data-guests-toddler="0" 
                        data-touched="0" 
                        data-ts-popup--container="[widget_el_data_id] .bw-pickers__picker-col--gp"
                        data-ts-popup-open="[bwgp_id]">
                        <span class="bw-pickers__picker-text bw-pickers__guestpicker-display">2 Adult</span>
                        <span class="bw-pickers__picker-toggle bw-icon--chevron-down"></span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--vessel">
                    <label>Type of Boat</label>
                    <div class="bw-pickers-picker-vessel">
                        <label for="bw-pickers-picker-vessel--catamaran" class="bw-pickers__picker-text">
                            <input type="checkbox" id="bw-pickers-picker-vessel--catamaran" name="vessel_type" value="catamaran"><span>65' Catamaran<br>Max 49 Guests<br>Ages 3+</span>
                        </label>
                        <label for="bw-pickers-picker-vessel--raft" class="bw-pickers__picker-text">
                            <input type="checkbox" id="bw-pickers-picker-vessel--raft" name="vessel_type" value="raft"><span>24' Raft<br>Max 14 Guests<br>Ages 6+</span>
                        </label>
                    </div>
                </div>
                <a href="#" class="bw-pickers__btn-submit bw-button bw-pickers__picker-col">[search_text]</a>
            </div>
        </div>
    </div>
`;

export const PICKERS_TEMPLATE_COMPACT = `
    <div class="bw bw-search">
        <div class="bw-pickers">
            <div class="bw-pickers-row">
                <div class="bw-pickers__picker-col bw-pickers__picker-col--info-0">
                    <h2>Book Your Adventure</h2>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--info-1">
                    <div class="bw-pickers__info">
                        <span class="bw-pickers__info__container">
                            <span class="bw-icon--image">${ICON_ALERT}</span>
                            <span class="bw-pickers__picker-text"><span class="bw-fw-bold">Best Price Guarantee!</span> You won't find Capt Andy's tours for less.</span>
                        </span>
                    </div>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--info-2">
                    <div class="bw-pickers__info">
                        <a href="#" data-ts-modal-open="bw-search-modal-bad-weather"  class="bw-pickers__info__container">
                            <span class="bw-icon--image">${ICON_HELP}</span>
                            <div>
                                <span class="bw-pickers__picker-text">Book early in your vacation to allow rescheduling around bad weather.</span><span class="bw-icon--image bw-icon--image--info">${ICON_INFO}</span>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--info-3 bw-pickers__picker-col--cancel-free-full">
                    <div class="bw-pickers__info">
                        <a href="#" data-ts-modal-open="bw-search-modal-cancel-free" class="bw-pickers__info__container">
                            <span class="bw-icon--image">${ICON_CHECKMARK}</span>
                            <div><span class="bw-pickers__picker-text" data-target="cancel-text" data-target-format="html">[cancel_text]
                            </span><span class="bw-icon--image bw-icon--image--info">${ICON_INFO}</span></div>
                        </a>
                    </div>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--info-4">
                    <div class="bw-pickers__info">
                        <span class="bw-pickers__info__container bw-payment-secure-text">
                            <span class="bw-icon--image">${ICON_LOCK}</span>
                            <span class="bw-pickers__picker-text">Payments are secure</span>
                        </span>
                    </div>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--cancel-free">
                    <button id="bw-pickers-picker-cancel-free" class="bw-pickers__picker bw-pickers__picker--cancel-free" data-touched="0">
                        <span class="bw-pickers__button">
                            <span class="bw-icon--image">${ICON_CHECKMARK}</span>
                            <span class="bw-pickers__picker-text">Cancel<br>Free</span>
                        </span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--date">
                    <label for="bw-pickers-picker-datepicker" class="bw-pickers--mounted-short-label">Tour Date</label>
                    <label for="bw-pickers-picker-datepicker">Date</label>
                    <button id="bw-pickers-picker-datepicker" class="bw-pickers__picker bw-pickers__picker--datepicker" data-touched="0">
                        <span class="bw-pickers__button">
                            <span class="bw-pickers__picker-text bw-pickers__datepicker-display"></span>
                            <span class="bw-pickers__picker-toggle bw-icon--chevron-down"></span>
                        </span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--gp">
                    <label for="bw-pickers-picker-guestpicker" class="bw-pickers--mounted-short-label">Passengers</label>
                    <label for="bw-pickers-picker-guestpicker">Guests</label>
                    <button id="bw-pickers-picker-guestpicker" class="bw-pickers__picker bw-pickers__picker--guestpicker" 
                        data-guests-adult="2" 
                        data-guests-child="0" 
                        data-guests-toddler="0" 
                        data-touched="0" 
                        data-ts-popup--container="[widget_el_data_id] .bw-pickers__picker-col--gp"
                        data-ts-popup-open="[bwgp_id]">
                            <span class="bw-pickers__button">
                                <span class="bw-pickers__picker-text bw-pickers__guestpicker-display">2 Adult</span>
                                <span class="bw-pickers__picker-toggle bw-icon--chevron-down"></span>
                            </span>
                    </button>
                </div>
                <div class="bw-pickers__picker-col bw-pickers__picker-col--vessel">
                    <label>Type of Boat</label>
                    <div class="bw-pickers-picker-vessel">
                        <label for="bw-pickers-picker-vessel--catamaran" class="bw-pickers__picker-text">
                            <input type="checkbox" id="bw-pickers-picker-vessel--catamaran" name="vessel_type" value="catamaran"><span>65' Catamaran<br>Max 49 Guests<br>Ages 3+</span>
                        </label>
                        <label for="bw-pickers-picker-vessel--raft" class="bw-pickers__picker-text">
                            <input type="checkbox" id="bw-pickers-picker-vessel--raft" name="vessel_type" value="raft"><span>24' Raft<br>Max 14 Guests<br>Ages 6+</span>
                        </label>
                    </div>
                </div>
                <a href="#" class="bw-pickers__btn-submit bw-pickers__picker-col bw-pickers__picker-col--submit"><span class="bw-button">[search_text]</span></a>
            </div>
        </div>
    </div>
`;