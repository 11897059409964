import { rgb_add_transparency } from 'ui/visibility';
import { humanize_duration_from_date } from 'utils/date';

export function set_autocomplete_input_readonly(el, val) {
    el
        .addClass('form-control-plaintext')
        .attr('readonly', true)
        .off('click')
        .devbridgeAutocomplete('dispose')
    ;

    if (val != null) {
        el.val(val)
    }

    return el;
}

export function set_autocomplete_checkbox_readonly(el, val) {
    el
        .addClass('form-control-plaintext')
        .attr('readonly', true)
        .off('click')
        .on('click', function(e) {
            e.preventDefault();
            e.stopPropagation();
        })
        .devbridgeAutocomplete('dispose')
    ;

    if (val != null) {
        el
            .attr('checked', val == 1)
            .prop('checked', val == 1)
            .trigger('change')
        ;
    }

    return el;
}

export function update_data_targets_object(type, row, container) {
    switch (type) {
        case 'global':
            container = container || $('body');
            
            update_data_targets({
                today_short: (new Date()).toShortHuman(),
            }, container);
            break;
        case 'conversation-message':
            update_data_targets({
                conversation_message: row.message.replace(/\n/g, "<br/>"),
                conversation_date: humanize_duration_from_date((Date.convertISOtoDate(row.modified_time)).fromUTC())
            }, container);
            break;
        case 'log-reservation':
            update_data_targets({
                log_time: (Date.convertISOtoDate(row.log_time)).fromUTC().toTimeHuman(),
                log_date: (Date.convertISOtoDate(row.log_time)).fromUTC().toShortHuman(),
                first_name: row.user[0].first_name,
                last_name: row.user[0].last_name,
                primary_passenger_first_name: row.contact ? row.contact[0].first_name : null,
                primary_passenger_last_name: row.contact ? row.contact[0].last_name : null,
                reservation_number: row.reservation ? row.reservation[0].number : null,
                notes: row.notes,
                description: row.description,
            }, container);
            break;
        case 'latest-messages':
            update_data_targets({
                modified_time: (Date.convertISOtoDate(row.modified_time)).fromUTC().toTimeHuman(),
                reservation_number: (row.passenger && row.passenger[0].reservation_id) ? row.passenger[0].reservation_id.padStart(8, '0') : null,
                subject: row.subject,
                snippet: row.conversation[0].snippet,
            }, container);
            if (row.status == 'UNREAD') {
                $('span[data-target="subject"]', container).addClass('font-weight-bold');
            }
            else {
                $('span[data-target="subject"]', container).removeClass('font-weight-bold');
            }
            break;
        case 'tour-calendar':
            update_data_targets({
                tour_calendar_date: (Date.convertISOtoDate(row.tour_calendar_date)).toWeekdayShortHuman(),
            }, container);
            break;
        case 'reservation-note':
            update_data_targets({
                created_time: row.created_time ? (Date.convertISOtoDate(row.created_time)).fromUTC().toTimeHuman() : null,
                created_date: row.created_time ? (Date.convertISOtoDate(row.created_time)).fromUTC().toShortHuman() : null,
                first_name: row.user[0].first_name,
                last_name: row.user[0].last_name,
                note: row.note,
            }, container);
            break;
        case 'tour-vessel-note':
            update_data_targets({
                created_time: (Date.convertISOtoDate(row.created_time)).fromUTC().toTimeHuman(),
                created_date: (Date.convertISOtoDate(row.created_time)).fromUTC().toShortHuman(),
                first_name: row.user[0].first_name,
                last_name: row.user[0].last_name,
                note: row.note,
            }, container);
            break;
        case 'tour-vessel-passenger-logs':
            if (row.type == 'tour_vessel_note') {
                update_data_targets({
                    created_time: (Date.convertISOtoDate(row.created_time)).fromUTC().toTimeHuman(),
                    created_date: (Date.convertISOtoDate(row.created_time)).fromUTC().toShortHuman(),
                    first_name: row.user[0].first_name,
                    last_name: row.user[0].last_name,
                    note: row.note,
                }, container);
            }
            else {
                update_data_targets({
                    created_time: (Date.convertISOtoDate(row.log_utc_dtm)).fromUTC().toTimeHuman(),
                    created_date: (Date.convertISOtoDate(row.log_utc_dtm)).fromUTC().toShortHuman(),
                    first_name: row.first_name,
                    last_name: row.last_name,
                    note: 'Res #' + row.number + ': ' + row.description,
                }, container);
            }
            break;
        case 'reservation-payment-summary':
            update_data_targets({
                amount_base: row.amount_base ? Number(row.amount_base).Round(2).toFixed(2) : '0.00',
                amount_product_base: row.amount_product_base ? Number(row.amount_product_base).Round(2).toFixed(2) : '0.00',
                amount_tax_total: row.amount_tax_total ? Number(row.amount_tax_total).Round(2).toFixed(2) : '0.00',
                amount_fees: row.amount_fees ? Number(row.amount_fees).Round(2).toFixed(2) : '0.00',
                amount_commission_total: row.amount_commission_total ? Number(row.amount_commission_total).Round(2).toFixed(2) : '0.00',
                amount_discount: row.amount_discount ? Number(row.amount_discount).Round(2).toFixed(2) : '0.00',
                amount_promo: row.amount_promo ? Number(row.amount_promo).Round(2).toFixed(2) : '0.00',
                amount_total: row.amount_total ? Number(row.amount_total).Round(2).toFixed(2) : '0.00',
                amount_payment_total: row.amount_payment_total ? Number(row.amount_payment_total).Round(2).toFixed(2) : '0.00',
                amount_voucher_total: row.amount_voucher_total ? Number(row.amount_voucher_total).Round(2).toFixed(2) : '0.00',
                amount_due: row.amount_due ? Number(row.amount_due).Round(2).toFixed(2) : '0.00',
                amount_payment_due: row.amount_payment_due ? Number(row.amount_payment_due).Round(2).toFixed(2) : '0.00',
                amount_voucher_due: row.amount_voucher_due ? Number(row.amount_voucher_due).Round(2).toFixed(2) : '0.00',
                amount_due_detail: row.amount_due >= 0 ? 'Due:' : 'Change:',
            }, container);

            update_data_alerts({
                amount_due_detail: row.amount_due > 0 ? 'warning' : 'success',
                amount_due: row.amount_due > 0 ? 'danger' : 'success',
                amount_due_container: row.amount_due > 0 ? 'danger' : '',
                amount_payment_due: row.amount_payment_due > 0 ? 'danger' : 'success',
                amount_payment_due_container: row.amount_payment_due > 0 ? 'danger' : '',
                amount_voucher_due: row.amount_voucher_due > 0 ? 'danger' : 'success',
                amount_voucher_due_container: row.amount_voucher_due > 0 ? 'danger' : '',
            }, container);
        case 'reservation':
            let json = {
                // we keep this in here because it is not changed as we navigate the reservation screen
                // its only updated on first load, and otherwise we have to pass data to the calc totals method
                amount_paid: row.amount_paid ? (Number(row.amount_paid)).Round(2).toFixed(2) : null,
                amount_payment_paid: row.amount_payment_paid ? (Number(row.amount_payment_paid)).Round(2).toFixed(2) : '0.00',
                amount_voucher_paid: row.amount_voucher_paid ? (Number(row.amount_voucher_paid)).Round(2).toFixed(2) : '0.00',

                primary_first_name: row.contact ? row.contact[0].first_name : null,
                primary_last_name: row.contact ? row.contact[0].last_name : null,
                reservation_number: row.number ? row.number : null,
            };

            // only update the thing if its present, which we should do everywhere
            if (row.hasOwnProperty('is_waitlisted')) {
                json.is_waitlisted = (row.is_waitlisted == true);
            }
            if (row.hasOwnProperty('waitlist_created_date')) {
                json.waitlist_created_date = (Date.convertISOtoDate(row.waitlist_created_time)).toTimeHuman();
            }
            if (row.hasOwnProperty('status')) {
                json.status = row.status;
            }
            if (row.hasOwnProperty('id')) {
                json.reservation_id = row.id;
            }
            if (row.hasOwnProperty('passenger_count')) {
                json.passenger_count = row.passenger_count;
            }
            if (row.hasOwnProperty('passenger_count_child')) {
                json.passenger_count_child = row.passenger_count_child;
            }
            if (row.hasOwnProperty('passenger_count_adult')) {
                json.passenger_count_adult = row.passenger_count_adult;
            }
            if (row.hasOwnProperty('reservation_group')) {
                json.reservation_group_name = row.reservation_group[0].name;
            }
            if (row.hasOwnProperty('group_id')) {
                json.reservation_group_id = row.group_id;
            }
            if (row.hasOwnProperty('user')) {
                json.created_by_name = row.user[0].first_name + ' ' + row.user[0].last_name;
            }
            if (row.hasOwnProperty('agency_voucher_number')) {
                json.agency_voucher_number = row.agency_voucher_number;
            }
            if (row.hasOwnProperty('agency_name')) {
                json.agency_name = row.agency_name;
            }
            if (row.hasOwnProperty('manifest_note')) {
                json.manifest_note = row.manifest_note;
            }
            if (row.hasOwnProperty('waitlist_end_time')) {
                json.waitlist_end_time = row.waitlist_end_time;
            }
            if (row.hasOwnProperty('waitlist_dates')) {
                json.waitlist_dates = row.waitlist_dates ? JSON.parse(row.waitlist_dates) : '';
            }
            if (row.hasOwnProperty('is_ridealong')) {
                json.is_ridealong = row.is_ridealong;
            }
            if (row.cancel_number) {
                json.cancel_number = '(#' + row.cancel_number + ')';
            }

            update_data_targets(json, container);
            break;
        case 'reservation-search-result-summary':
            update_data_targets({
                results_total: ' - ' + row.meta.count,
            }, container);
            break;
        case 'passenger':
            let row_contact = row.contact ? row.contact[0] : {};
            update_data_targets({
                amount: row.amount ? (Number(row.amount)).Round(2).toFixed(2) : null,
                base: row.base ? (Number(row.base)).Round(2).toFixed(2) : null,
                commission: row.commission ? (Number(row.commission)).Round(2).toFixed(2) : '0.00',
                bill: row.bill ? (Number(row.bill)).Round(2).toFixed(2) : '0.00',
                contact_id: row.contact_id,
                discount_code_id: row.discount_code_id ? row.discount_code_id : '',
                discount: row.discount ? (Number(row.discount)).Round(2).toFixed(2) : null,
                email: row_contact.email,
                fees: row.fees ? (Number(row.fees)).Round(2).toFixed(2) : null,
                first_name: row_contact.first_name,
                hotel_id: row.hotel_id,
                hotel_name: row.hotel ? row.hotel[0].name : null,
                is_child: row.is_child,
                last_name: row_contact.last_name,
                list_food_preference: row.food_preference,
                passenger_id: row.id,
                phone: row_contact.phone,
                passenger_status: row.status ? row.status : 'Active',
                tax: row.tax ? (Number(row.tax)).Round(2).toFixed(2) : null,
            }, container);

            update_data_alerts({
                is_checked_in: row.is_checked_in == 1 ? 'success' : 'warning',
                is_paid: row.reservation ? (row.reservation[0].is_paid == 1 ? 'success' : 'warning') : '',
                has_waiver: row.has_waiver == 1 ? 'success' : 'warning',
            }, container);

            $('input[type="hidden"][name="is_child"]', container).attr('disabled', row.is_child == 1);

            break;
        case 'product':
            update_data_targets({
                product_id: row.product[0].id,
                reservation_product_id: row.id,
                name: row.product[0].name,
                type: row.product[0].type,
                tax: row.tax ? (Number(row.tax)).Round(2).toFixed(2) : '0.00',
                base: (Number(row.base)).Round(2).toFixed(2),
                commission: row.commission ? (Number(row.commission)).Round(2).toFixed(2) : '0.00',
                commissionable: row.commissionable,
            }, container);
            break;
        case 'product-price':
            update_data_targets({
                tax: (Number(row.tax)).Round(2).toFixed(2),
                base: (Number(row.base)).Round(2).toFixed(2),
                commission: row.commission ? (Number(row.commission)).Round(2).toFixed(2) : '0.00',
            }, container);
            break;
        case 'contact-price':
            update_data_targets({
                tax: (Number(row.tax)).Round(2).toFixed(2),
                fees: (Number(row.fees)).Round(2).toFixed(2),
                base: (Number(row.base)).Round(2).toFixed(2),
                total: (Number(row.total)).Round(2).toFixed(2),
                discount: (Number(row.discount)).Round(2).toFixed(2),
                discount_code_id: row.discount_code_id ? row.discount_code_id : '',
                commission: row.commission ? (Number(row.commission)).Round(2).toFixed(2) : '0.00',
                bill: row.bill ? (Number(row.bill)).Round(2).toFixed(2) : '0.00',
            }, container);
            break;
        case 'tour':
            update_data_targets({
                tour_name: row.name,
            }, container);
            break;
        case 'tour_vessel':
            let calculated_capacity_available = row.hasOwnProperty('capacity_available') ? row.capacity_available : null;
            if (row.hasOwnProperty('capacity_available') && row.hasOwnProperty('block_count')) {
                calculated_capacity_available = row.capacity_available - row.block_count;
            }

            update_data_targets({
                start_time: row.start_time ? (Date.convertISOtoDate(row.start_time)).toTimeHuman() : '',
                checkin_time: row.checkin_time ? (Date.convertISOtoDate(row.checkin_time)).toTimeHuman() : '',
                start_long_date: row.start_time ? (Date.convertISOtoDate(row.start_time)).toShortWithDayOfWeekHuman() : '',
                start_date: row.start_time ? (Date.convertISOtoDate(row.start_time)).toShortHuman() : '',
                end_time: row.end_time ? (Date.convertISOtoDate(row.end_time)).toTimeHuman() : '',
                tour_name: row.tour ? row.tour[0].name : null,
                status: row.status ? row.status : null,
                vessel_name: row.vessel ? row.vessel[0].name : null,
                capacity_available: calculated_capacity_available,
                capacity_blocked: row.block_count == null ? 0 : row.block_count,
                capacity_max: row.vessel ? row.vessel[0].capacity_max : null,
                total_booked: row.vessel ? row.vessel[0].capacity_max - row.capacity_available - row.block_count : null,
                adult_booked: row.passenger_count_adult ? row.passenger_count_adult : 0,
                child_booked: row.passenger_count_child ? row.passenger_count_child : 0,
                checkin_count: row.checkin_count ? row.checkin_count : 0,
                uncheckin_count: row.uncheckin_count ? row.uncheckin_count : 0,
                captain_initials: row.user ? (row.user[0].first_name == null ? '' : row.user[0].first_name.substr(0, 1) + row.user[0].last_name.substr(0, 1)) : null,
                captain_name: row.user ? (row.user[0].first_name == null ? '' : row.user[0].first_name + ' ' + row.user[0].last_name) : null,
                start_location_name: row.location ? row.location[0].name : null,
                destination_location_name: row.destination_location_name ? row.destination_location_name : null,
                target_vessel_name: row.vessel ? (row.vessel.length > 1 ? row.vessel[1].name : null) : null,
                is_charter: row.is_charter ? (row.is_charter == 1 ? 'Charter' : '') : null,
                is_alternate_destination: row.is_alternate_destination ? (row.is_alternate_destination == 1 ? row.destination_location_name.substr(0, 1) : '') : null,
                alternate_destination_name: row.is_alternate_destination ? (row.is_alternate_destination == 1 ? row.destination_location_name : '') : null,
                notes: row.notes ? row.notes : null,
            }, container);

            update_meal_count_data_targets(row, container);

            if (row.hasOwnProperty('is_active')) {
                update_data_alerts({
                    is_active: row.is_active == 1 ? 'success' : 'warning',
                    is_active_title: row.is_active == 1 ? 'Active' : 'Inactive',
                }, container);
            }
            if (calculated_capacity_available !== null) {
                update_data_alerts({
                    capacity_available: row.capacity_available_alert ? row.capacity_available_alert : numerical_alert(calculated_capacity_available),
                    capacity_available_title: calculated_capacity_available > 0 ? 'Available' : 'Unavailable',
                }, container);
            }

            if (row.hasOwnProperty('is_charter')) {
                toggle_class({
                    is_charter: 'tour-vessel-is-charter'
                }, container, (row.is_charter == 1));
            }

            if (row.hasOwnProperty('tour')) {
                update_style({
                    tour_bg_color: row.tour[0].color,
                }, container);
            }
            break;

        case 'transaction':
            update_data_targets({
                created_time: row.created_time ? (Date.convertISOtoDate(row.created_time)).fromUTC().toTimeHuman() : null,
                created_date: row.created_time ? (Date.convertISOtoDate(row.created_time)).fromUTC().toShortHuman() : null,
                transaction_id: row.id,
                method_number: row.method_number,
                method: row.method,
                processor: row.processor,
                first_name: row.user ? row.user[0].first_name : null,
                last_name: row.user ? row.user[0].last_name : null,
                type: row.type,
                amount: (Number(row.amount)).Round(2).toFixed(2),
                amount_refund: (Number(row.amount_refund)).Round(2).toFixed(2),
                amount_remain: (Number(row.amount_remain)).Round(2).toFixed(2),
                is_refunded: row.is_refunded == 1,
                remote_id: row.remote_id,
                is_successful: row.is_successful,
            }, container);
            break;
        case 'promo_code':
            update_data_targets({
                promo_code_id: row.id ? row.id : null,
                promo_code_value: row.value ? row.value : null,
                promo_code_value_type: row.value_type ? row.value_type : null,
                promo_code_value_entity: row.value_entity ? row.value_entity : null,
                promo_code: row.code ? row.code : null,
                promo_code_summary: row.promo_code_summary ? row.promo_code_summary : null
            }, container);

            toggle_class({
                show_promo_remove: 'd-none'
            }, container, (row.promo_code_summary ? false : true) );

            break;
    }

    $('[data-toggle="tooltip"]', container).tooltip();
}

// requires food_preferences to be defined
export function update_meal_count_data_targets(row, container) {
    // put the meal counts into their group
    let meal_counts = {};
    for (let i in food_preferences) {
        let pref = food_preferences[i];
        let target_key = 'meal_count_' + pref['group'];
        let api_col_name = 'meal_count_' + pref['key'];
        if (!(target_key in meal_counts)) {
            meal_counts[target_key] = 0;
        }
        // get the column and add it to the group
        meal_counts[target_key] += parseInt(row[api_col_name]) || 0;
    }

    update_data_targets(meal_counts, container);
}

function numerical_alert(num) {
    if (num == 0) {
        return 'warning';
    }

    if (num < 0) {
        return 'danger'
    }

    return 'success';
}

export function update_data_targets(json, container) {
    for (let key in json) {
        let els = $('[data-target="' + key.replace(/_/g, '-') + '"]', container);
        if (!els.length) {
            continue;
        }

        els.each(function () {
            let el = $(this);

            switch (el.prop('nodeName').toLowerCase()) {
                case 'select':
                    // if select has already been loaded w/ <options>, then select the right one.
                    // otherwise set a data-default attribute so when the select is loaded it'll get selected then.
                    if (el.has('option').length > 0) {
                        el.val(json[key]);
                    }
                    else {
                        el.data('default', json[key]);
                    }

                    break;
                case 'input':
                    // if its a datepicker, set the date if its not empty
                    if (el.hasClass('form-datepicker')) {
                        if (json[key] != null) {
                            let val = json[key];
                            if (Array.isArray(val)) {
                                val = val.map(function(s) {
                                    return Date.convertISOtoDate(s + ' 00:00:00');
                                });
                            }
                            else {
                                val = [Date.convertISOtoDate(json[key])];
                            }
                            el.datepicker('setDates', val);
                        }
                    }
                    else if (el.attr('type') != null && (el.attr('type').toLowerCase() == 'checkbox' || el.attr('type').toLowerCase() == 'radio')) {
                        let is_true = json[key] == 1 || json[key] == 'true' || json[key] == 'true';

                        el.attr('checked', is_true);
                        el.trigger('change');
                    }
                    else {
                        el
                            .val(json[key])
                            .attr('value', json[key]);
                    }

                    break;
                case 'textarea':
                    el.text(json[key]);

                    break;
                default:
                    switch (el.attr('data-target-format')) {
                        case 'html':
                            el.html(json[key]);
                            break;
                        case 'text':
                        default:
                            el.text(json[key]);
                    }

                    break;
            }

            // if there's a data-target-title attribute, set the title to something that's not the same key
            if (el.attr('data-target-title')) {
                let title_key = el.attr('data-target-title').replace(/-/g, '_');
                if (json[title_key]) {
                    el.attr('title', json[title_key]);
                }
            }

            // set the title to the data value if there isn't already a title
            let el_title = el.attr('title');
            if (el_title == undefined || el_title == "" || el_title == null) {
                el.attr('title', json[key]);
            }
        });
    }
}

export function update_data_alerts(json, container) {
    for (let key in json) {
        let el = $('[data-target=' + key.replace(/_/g, '-') + ']', container);

        if (!el.length) {
            continue;
        }

        if (el.hasClass('alert')) {
            el.removeClass('alert-danger alert-warning alert-success alert-info alert-secondary');
            el.addClass('alert-' + json[key]);
        }

        if (el.hasClass('alert-text-color')) {
            el.removeClass('text-danger text-warning text-success text-info');
            if (json[key] != '') {
                el.addClass('text-' + json[key]);
            }
        }

        if (el.hasClass('badge')) {
            el.removeClass('badge-danger badge-warning badge-success badge-info badge-secondary');
            el.addClass('badge-' + json[key]);
        }

        if (el.hasClass('indicator')) {
            el.removeClass('indicator-danger indicator-warning indicator-success indicator-info');
            el.addClass('indicator-' + json[key]);
        }
        if (el.attr('data-target-title')) {
            let title_key = el.attr('data-target-title').replace(/-/g, '_');
            if (json[title_key]) {
                el.attr('title', json[title_key]);
            }
        }

    }
}

export function toggle_class(json, container, on = true) {

    // the class might go on the container itself
    for (let key in json) {
        let el = $('[data-target-class-' + key.replace(/_/g, '-') + ']', container)
            .add(container.filter('[data-target-class-' + key.replace(/_/g, '-') + ']'))
        ;

        if (!el.length || json[key] == undefined) {
            continue;
        }

        if (on) {
            el.addClass(json[key]);
        }
        else {
            el.removeClass(json[key]);
        }
    }
}

export function update_style(json, container) {

    for (let key in json) {
        let el = $('[data-target=' + key.replace(/_/g, '-') + ']', container);
        if (!el.length || json[key] == undefined) {
            continue;
        }
        if (key.includes('bg_color')) {
            el.parent().css('background-color', rgb_add_transparency(json[key]));
        }
    }
}



export function form_to_object(form_el) {
    let arrayData, objectData;
    arrayData = form_el.serializeArray();
    objectData = {};

    $.each(arrayData, function() {
        let value;

        if (this.value != null) {
            value = this.value;
        } else {
            value = '';
        }

        if (objectData[this.name] != null) {
            if (!objectData[this.name].push) {
                objectData[this.name] = [objectData[this.name]];
            }

            objectData[this.name].push(value);
        } else {
            objectData[this.name] = value;
        }
    });

    return objectData;
};