let MINUTE_IN_MILISECONDS = 60000;

export const to_system_date = (date, with_time) => {
    let str = date.getFullYear()
        + '-' + (date.getMonth() + 1).toString().padStart(2, '0')
        + '-' + date.getDate().toString().padStart(2, '0');
    if (typeof with_time !== 'undefined' && with_time) {
        str +=  ' ' + to_system_time(date);
    }
    return str;
}

export const to_system_date_utc = (date, with_time) => {
    let str = date.getUTCFullYear()
        + '-' + (date.getUTCMonth() + 1).toString().padStart(2, '0')
        + '-' + date.getUTCDate().toString().padStart(2, '0');
    if (typeof with_time !== 'undefined' && with_time) {
        let hr = date.getUTCHours();
        let min = date.getUTCMinutes();
        let sec = date.getUTCSeconds();
    
        if (hr < 10) {
            hr = '0' + hr;
        }
        if (min < 10) {
            min = '0' + min;
        }
        if (sec < 10) {
            sec = '0' + sec;
        }
    
        str += ' ' + hr + ':' + min + ':' + sec;
    }
    return str;
}


export const to_system_time = (date) => {
    let hr = date.getHours();
    let min = date.getMinutes();
    let sec = date.getSeconds();

    if (hr < 10) {
        hr = '0' + hr;
    }
    if (min < 10) {
        min = '0' + min;
    }
    if (sec < 10) {
        sec = '0' + sec;
    }

    return hr + ':' + min + ':' + sec;
}

export const is_valid_timestamp = (timestamp) => {
    return is_valid_date(timestamp_to_date(timestamp));
}

export const is_valid_date = (date) => {
    return (date instanceof Date && !isNaN(date));
}

export const timestamp_to_date = (t) => {
    let timestamp = parseInt(t) || false;
    if (timestamp === false) {
        return null;
    }
    let d = new Date(timestamp);
    
    if (!is_valid_date(d)) {
        return null;
    }

    return d;
}

// convert Y-m-d H:i:S to a Date object
// iPhone Safari doesn't support the Y-m-d H:i:s (ISO 8601) date format.
export const convertISOtoDate = (iso_datestring) => {
    if (typeof iso_datestring !== 'string' && !(iso_datestring instanceof String)) {
        return new Date(iso_datestring);
    }

    let arr = iso_datestring.split(/[- :]/);
    if (arr.length != 6) {
        // not in the format we expect, just hope it works
        return new Date(iso_datestring);
    }
    let date = new Date(arr[0], arr[1]-1, arr[2], arr[3], arr[4], arr[5]);
    return date;
}

export const from_utc = (date) => {
    let dt = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    return dt;
}

// return a date, in the local timezone, but with date and times representing HST.
export const local_to_tz_display = (date, offset) => {
    let utc_time = date.getTime() + (date.getTimezoneOffset() * 60000);
    return new Date(utc_time + (3600000 * offset));
}

export const tz_display_to_local = (date, offset) => {
    let utc_time = date.getTime() + (date.getTimezoneOffset() * 60000);
    let diff = Math.abs(-(date.getTimezoneOffset() * 60000) - (3600000 * offset))
    return new Date(utc_time + diff - (date.getTimezoneOffset() * 60000));
}

export const format_date_input = (input_el) => {
    input_el.off('keyup').on('keyup', function(e){
        let input_char = String.fromCharCode((typeof e.which == "number") ? e.which : e.keyCode);
        if (!input_char.match(/^[a-z0-9]$/i)) {
            return;
        }
        let cursor_position = this.selectionStart;
        let is_at_end = (cursor_position == $(this).val().length);
        $(this).val(parse_date_input($(this).val()));
        if (!is_at_end) {
            // only re-set the position if editing in the middle
            this.selectionStart = cursor_position;
            this.selectionEnd = cursor_position;
        }
    });
}

export const parse_date_input = (input_str) => {
    if (input_str.length == 0) {
        return input_str;
    }
    let numbers = input_str.replace(/\D/g,'');
    if (numbers.length <= 2) {
        return numbers;
    }

    let new_str = numbers.substring(0, 2) + '/';

    if (numbers.length > 4) {
        new_str += numbers.substring(2, 4) + '/';
        new_str += numbers.substring(4);
    }
    else {
        new_str += numbers.substring(2);
    }

    return new_str;
}

/**
 * Returns the humanized duration (up to 2 hours) or local date as a string.
 * For more complex durations, use a library
 */
 export const humanize_duration_from_date = (date) => {
    let target_time = date.getTime();
    let now = Date.now();
    let duration = Math.abs(now - target_time);
    let suffix = now < target_time ? ' from now' : ' ago';

    if (duration < MINUTE_IN_MILISECONDS) {
        let seconds = Math.floor(duration/1000);
        return seconds + ' second' + ((seconds > 1) ? 's' : '') +  suffix;
    }
    else if (duration < 60*MINUTE_IN_MILISECONDS) {
        let minutes = Math.floor(duration/MINUTE_IN_MILISECONDS);
        minutes = minutes == 0 ? 1 : minutes;
        return minutes + ' minute' + ((minutes > 1) ? 's' : '') +  suffix;
    }
    else if (duration < 120*MINUTE_IN_MILISECONDS) {
        let hours = Math.floor(duration/(60*MINUTE_IN_MILISECONDS));
        hours = hours == 0 ? 1 : hours;
        return hours + ' hour' + ((hours > 1) ? 's' : '') +  suffix;
    }

    return date.toShortHuman() + ' ' + date.toTimeHuman();
}

/**
 * Given an array of date strings (no time), return the latest date as string
 */
export const get_latest_date = (date_strings) => {
    let date_objs = date_strings.map((date_string) => {
        return convertISOtoDate(date_string + ' 00:00:00');
    });
    let latest_date = date_objs.reduce((max_dt, curr_dt) => {
        return curr_dt > max_dt ? curr_dt : max_dt;
    }, new Date('1970-01-01Z00:00:00:000'));
    
    return to_system_date(latest_date)
}

/**
 * Get the current day if it's before 4pm hst, otherwise tomorrow
 */
export const get_default_date = (utc_offset) => {
    if (!utc_offset) {
        utc_offset = -10;
    }
    const hst_now_dt = local_to_tz_display(new Date(), utc_offset);
    let min_date = new Date(hst_now_dt.toDateString());
    if (hst_now_dt.getHours() > 16) {
        min_date.setDate(min_date.getDate() + 1);
    }

    return min_date;
}