/*global __tour_vessel_statuses */

import { get as get_data, patch as patch_data, post as post_data } from 'data/data';
import log from 'utils/log';

// for common reservations
const apicols = [
    'agency_id',
    'agency_desk_id',
    'agent_user_id',
    'agency_voucher_number',
    'amount_base',
    'amount_bill',
    'amount_commission',
    'amount_discount',
    'amount_due',
    'amount_fees',
    'amount_net',
    'amount_paid',
    'amount_payment_paid',
    'amount_voucher_paid',
    'amount_product_base',
    'amount_product_commission',
    'amount_product_tax',
    'amount_product_total',
    'amount_tax',
    'amount_total',
    'cancel_number',
    'cancel_time',
    'checkin_count',
    'uncheckin_count',
    'group_id',
    'id',
    'is_active',
    'promo_code_id',
    'is_paid',
    'is_waitlisted',
    'is_ridealong',
    'manifest_note',
    'number',
    'ridealong_count',
    'passenger_count',
    'passenger_count_adult',
    'passenger_count_child',
    'primary_passenger_id',
    'reservation_type',
    'status',
    'trip_id',
    'waitlist_dates',
    'waitlist_created_time',
    'waitlist_end_time',
    'created_time',
    'modified_time'
];

export const apiselect = apicols
                            .map(function (value) { return 'reservation.' + value; })
                            .join(',')
                        ;


export const get_gift_certificate = (options) => {
    if (options == null) {
        options = {};
    }

    if (!'id' in options) {
        return;
    }

    let defaults = {
        endpoint: 'reservation/gift_certificate',
        fields: 'id,amount_payment_paid,number',
        filters: 'id.eq(' + options.id + ')'
    }
    delete options.id;

    return get_data($.extend(true, {}, defaults, options));
}

// checks in a whole reservation
// options.reservation_id
export const checkin = (options) => {
    if (options == null) {
        options = {};
    }


    let options_on_done = options.on_done || (function() {});
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.reservation_id = options.reservation_id || null;

    delete options.on_done;

    log('checking in all passengers in reservation ' + options.reservation_id);

    let defaults = {
        endpoint: 'reservation/' + options.reservation_id + '/checkin',
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }

    return get_data($.extend(true, {}, defaults, options));
}

// boards a whole reservation (only pax with waivers)
// options.reservation_id
export const board = (options) => {
    if (options == null) {
        options = {};
    }


    let options_on_done = options.on_done || (function() {});
    options.on_success = options.on_success || (function() {});
    options.on_fail = options.on_fail || (function() {});
    options.reservation_id = options.reservation_id || null;

    delete options.on_done;

    log('boarding all passengers in reservation ' + options.reservation_id);

    let defaults = {
        endpoint: 'reservation/' + options.reservation_id + '/board',
        on_done: function(json) {
            if (json.success == true) {
                options.on_success(json);
            }
            else {
                options.on_fail(json);
            }

            options_on_done(json);
        }
    }

    return get_data($.extend(true, {}, defaults, options));
}

export const get = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'reservation'
    }
    
    return get_data($.extend(true, {}, defaults, options));
}

export const patch = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'reservation'
    }
    
    return patch_data($.extend(true, {}, defaults, options));
}

export const post = (options) => {
    if (options == null) {
        options = {};
    }

    let defaults = {
        endpoint: 'reservation'
    }
    
    return post_data($.extend(true, {}, defaults, options));
}

export const get_status_shortcode = (tour_vessel_data) => {
    if (tour_vessel_data.is_charter == 1) {
        return 'CH';
    }
    switch(tour_vessel_data.status) {
        case __tour_vessel_statuses.STATUS_ACTIVE:
            return 'A'
        break;
        case __tour_vessel_statuses.STATUS_ON_HOLD:
            return 'H'
        break;
        case __tour_vessel_statuses.STATUS_WEATHER_CANCEL:
            return 'WX'
        break;
        case __tour_vessel_statuses.STATUS_DRY_DOCK:
            return 'DD'
        break;
        case __tour_vessel_statuses.STATUS_MAINTENANCE:
            return 'M'
        break;
        case __tour_vessel_statuses.STATUS_MAINTENANCE_CANCEL:
            return 'MX'
        break;
        case __tour_vessel_statuses.STATUS_NO_PASSENGERS:
            return 'NP'
        break;
        case __tour_vessel_statuses.STATUS_TURN_AROUND:
            return 'TA'
        break;
    }
    return false;
}